<template>
	<div class="select-express">
		<!-- <Select v-model="express" filterable @on-change="handleChange">
            <Option v-for="item in optionData" :value="item.id" :key="item.id">{{ item.name }}
            </Option>
        </Select> -->
		<div class="express-tag">
			<span v-for="item in common" :key="item.id" :class="{ checked: item.id === express }"
				@click="handleChange(item.id)">
				{{ item.name }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "SelectExpress",
	props: {
		value: {
			type: [String, Number],
			required: true
		},
		optionData: {
			type: Array,
			default: () => [],
			required: true
		},
		common: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			express: '',
		};
	},
	watch: {
		value: {
			handler() {
				this.express = this.value;
			},
			immediate: true
		}
	},
	created() {
	},
	methods: {
		handleChange(value) {
			if (value !== this.express) {
				this.express = value;
			}
			this.$emit("update:value", value);
		}
	}
};
</script>

<style scoped lang="scss">
.select-express {
	width: 100%;

	>.express-tag {
		/* margin-top: 10px; */

		>span {
			@include font-14-20;
			color: $text-second;
			padding: 1px 7px;
			border: 1px solid $border-color;
			border-radius: 2px;
			margin-right: 10px;
			cursor: pointer;
			display: inline-block;

			&:last-child {
				margin-right: 0;
			}
		}

		>.checked {
			background-color: $brand-color;
			border-color: $brand-color;
			color: #ffffff;
		}
	}
}
</style>
