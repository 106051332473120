<template>
	<kdx-modal-frame v-model="value" :title="item.title" :width="700" :loading="loading" @on-cancel="handleCancel"
		@on-ok="handleOk">
		<div class="send-goods-modal">
			<Form v-if="value" ref="form" :model="order" :rules="rules" :label-width="120" @submit.native.prevent>
				<!-- <FormItem label="处理方式：" class="r-form-item-checkbox" v-if="dispatch_type === '10'">
					<RadioGroup v-model="order.type">
						<Radio label="1" :disabled="disabled">整单发货</Radio>
						<Radio label="0" :disabled="disabled">分包裹发货</Radio>
					</RadioGroup>
				</FormItem> -->
				<!--分包裹商品-->
				<!-- <div class="shop-more" v-if="order.type === '0' && dispatch_type === '10'">
                    <kdx-hint-alert class="alert">选择需要发货的商品</kdx-hint-alert>
                    <send-goods-list :data="data.order_goods" @on-change="changeSendGoods"></send-goods-list>
                </div> -->
				<FormItem label="收货人姓名：" class="r-form-item-text">
					{{ item.name || '-' }}
				</FormItem>
				<FormItem label="联系方式：" class="r-form-item-text">
					{{ item.mobile || '-' }}
				</FormItem>
				<FormItem label="收货地址：" class="r-form-item-text">
					{{ item.address || '-' }}
				</FormItem>
				<FormItem label="快递公司：" prop="express_id" v-if="item.shopType == '1'">
					<select-express :value.sync="order.express_id" :option-data="express" :common="logistics"
						class="width-340"></select-express>
				</FormItem>
				<!-- <FormItem label="自定义名称：" prop="express_name" v-if="order.express_id == 96">
					<Input v-model="order.express_name" class="width-340" placeholder="请输入" maxlength="6" show-word-limit
						@on-enter="handleOk" />
					<kdx-hint-text>其他快递不支持物流查询，不支持打印电子面单</kdx-hint-text>
				</FormItem> -->
				<FormItem label="快递单号：" prop="express_code" v-if="item.shopType == '1'">
					<Input v-model="order.express_code" class="width-340" placeholder="请输入" @on-enter="handleOk" />
				</FormItem>
				<FormItem label="卡券号码：" prop="card" v-if="item.shopType == '2'">
					<Input v-model="order.card" class="width-340" placeholder="请输入" @on-enter="handleOk" />
				</FormItem>
			</Form>
		</div>
	</kdx-modal-frame>
</template>

<script>
import SelectExpress from "../SelectExpress";
// import SendGoodsList from './components/GoodsList';
export default {
	name: "SendGoods",
	components: {
		SelectExpress,
		// SendGoodsList 
	},
	props: {
		item: {
			type: [Object]
		},
		id: {
			type: [String, Number]
		},
		dispatch_type: {
			type: [String, Number],
		},
	},
	data() {
		return {
			value: false,
			order: {
				card: '',
				express_code: '', // 快递单号
				express_id: '', // 快递公司
				goodsId: [], // 商品id
				type: '1',
				city_distribution_type: '0', // 0商家配送 1达达
				express_name: '其他快递', //自定义其他快递名称
			},
			// 类型，是否禁止操作，当为一件商品和多包裹发货时禁用
			disabled: false,
			data: {},
			message: '',
			express: [],
			logistics: [],
			rules: {
				express_id: [
					{ required: true, message: '快递公司必填' }
				],
				express_code: [
					{ required: true, message: '快递单号必填' }
				],
				card: [
					{ required: true, message: '卡券号码必填' }
				],
				city_distribution_type: [
					{ required: true, message: '配送方式必选' }
				],
				express_name: [
					{ required: true, message: '自定义名称必填' }
				],
			},
			loading: false
		};
	},
	computed: {

	},
	methods: {
		setValue() {
			this.value = !this.value;
			if (this.value) {
				this.resetModel();
				this.getData();
			}
		},

		handleCancel() {
			this.setValue();
		},
		handleOk() {
			this.$refs['form'].validate(async valid => {
				if (valid) {
					const { id: sid } = this.item;
					const { express_id: logisticsId, express_code: order, card: cardCoupon } = this.order;
					if (this.item.eType == 'send') {
						await this.$api.goods.prizesend({ sid, logisticsId, order, cardCoupon }, { toast: 1 });
					} else {
						await this.$api.order.modfiyNumber({ id: sid, logistics_id: logisticsId, number: order }, { toast: 1 });
					}

					this.setValue();
					this.$emit('on-refresh');
				}
			});
		},

		// 获取数据
		async getData() {
			this.loading = true;
			const result = await this.$api.goods.logistics();
			this.logistics = result;
			this.loading = false;
		},
		resetModel() {
			this.order = {
				express_code: '', // 快递单号
				express_id: '', // 快递公司
				goodsId: [], // 商品id
				type: '1',
				city_distribution_type: '0', // 同城配送配送方式
				express_name: '其他快递',
				card: '',
			};
			this.express = [];
			this.disabled = false;
		}
	},
};
</script>

<style lang="scss">
.send-goods-modal {
	padding: 30px 0;

	.ivu-form {
		.ivu-form-item {
			margin-bottom: 20px;

			.ivu-form-item-label {
				padding-right: 0;
			}
		}
	}

	.ivu-table {
		.ivu-table-header {
			.ivu-table-cell {
				padding: 10px 15px;
			}
		}
	}

	.shop-more {
		margin: 0 40px 20px 40px;
		padding: 20px;
		background-color: $background-color;

		>.alert {
			margin-bottom: 10px;
		}
	}
}

.open-dispatch {
	color: $brand-color;
	cursor: pointer;
}
</style>
