<template>
    <kdx-modal-frame v-model="value" :title="title" :width="430" class-name="order-list-close-order"
                 @on-cancel="handleCancel" @on-ok="handleOk">
        <div class="order-list-close-order-remark">
            <Input v-model="model.remark" type="textarea" placeholder="请输入取消原因"/>
        </div>
    </kdx-modal-frame>
</template>

<script>
    export default {
        name: "index",
        props: {
            visible: {
                type: Number,
                default: 0
            },
            title: {
                type: String,
                default: '关闭订单'
            },
            id: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                model: {
                    remark: ''
                },
                value: false
            };
        },
        watch: {
            visible() {
                this.value = true;

            }
        },
        methods: {
            handleCancel() {
                this.value = false;
            },
            handleOk() {
                this.value = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    .order-list-close-order-remark {
        padding: 20px;
    }
</style>
